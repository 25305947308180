//functional component to create a form to upload the invoice image and extract the data from it.
//Call the API to extract the data and display it on the screen.

import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import AIService from "../services/AIService";

const Invoice = () => {
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [type, setType] = useState("0");
    const [responsedata, setResponseData] = useState("");

    //method to handle the form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", key);
        formData.append("type", type);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Loading...";
        submitButton.style.backgroundColor = "#6c757d";        

        //calling the extract method from AIService
        const response = await AIService.extract(formData);
        console.log(response);

        //Change button text back to "Submit" after response
        submitButton.disabled = false;
        submitButton.innerText = "Submit";
        submitButton.style.backgroundColor = "#0d6efd";

        //setting the data in the state
        setResponseData(response);
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <Card className="card">
                    <Card.Body>
                        <Card.Title> Invoice </Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label> Upload Invoice </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </Form.Group>                            
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>                        
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-8">
                <div className="data">
                    <h3> Extracted Data </h3>
                    
                    {/* Check if response data is not empty and reponsedata.status is success*/}
                    {responsedata && responsedata.status === "Success" ? (
                        <div class="invoice">
                            <div class="invoice-header">
                                <h1>Invoice</h1>
                            </div>
                            <div class="invoice-details">                                

                                {/* Check if billto is not null*/}
                                {responsedata.data.bill_to ? (
                                    <div>
                                        <p><strong>Bill To:</strong> {responsedata.data.bill_to.company_name}</p>
                                        <p><strong>Address:</strong> {responsedata.data.bill_to.address_line1}, {responsedata.data.bill_to.city}, {responsedata.data.bill_to.state} - {responsedata.data.bill_to.pincode}</p>
                                        <p><strong>GSTIN:</strong> {responsedata.data.bill_to.gstin}</p>
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                {/* Check if invoicedby is not null*/}
                                {responsedata.data.invoiced_by ? (
                                    <div>
                                        <p><strong>Invoiced By:</strong> {responsedata.data.invoiced_by.company_name}</p>
                                        <p><strong>Address:</strong> {responsedata.data.invoiced_by.address_line1}, {responsedata.data.invoiced_by.city}, {responsedata.data.invoiced_by.state} - {responsedata.data.invoiced_by.pincode}</p>
                                        <p><strong>GSTIN:</strong> {responsedata.data.invoiced_by.gstin}</p>
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                <br/>
                                <p><strong>Invoice Number:</strong> {responsedata.data.invoice_no}</p>
                                <p><strong>Invoice Date:</strong> {responsedata.data.invoice_date}</p>
                                <p><strong>Invoice Amount:</strong> {responsedata.data.invoice_amount}</p>
                            </div>
                            <table class="invoice-table">
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>HSN/SAC</th>
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        <th>SubTotal</th>
                                        <th>CGST</th>
                                        <th>SGST</th>
                                        <th>IGST</th>
                                        <th>GST Amount</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {responsedata.data.items.map((item) => (
                                        <tr>
                                            <td>{item.item}<br/><small>{item.description}</small></td>
                                            <td>{item.hsn_sac_code}</td>
                                            <td>{item.qty} {item.uom}</td>
                                            <td>{item.rate}</td>
                                            <td>{item.subtotal}</td>
                                            <td>{item.cgst_rate}</td>
                                            <td>{item.sgst_rate}</td>
                                            <td>{item.igst_rate}</td>
                                            <td>{item.gst_amount}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div class="invoice-total">
                                <p><strong>Sub Total:</strong> {responsedata.data.subtotal_amount}</p>
                                <p><strong>GST Total:</strong> {responsedata.data.gsttotal_amount}</p>
                                <p><strong>Payable Amount:</strong> {responsedata.data.payable_amount}</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p> No data found </p>
                        </div>
                    )}
                    
                   </div>
            </div>
        </div>
    );
};

export default Invoice;