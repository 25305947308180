//functional component to upload the challan image and extract the data from it.
//Call the API to extract the data and display it on the screen.

import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import AIService from "../services/AIService";

const Challan = () => {
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [type, setType] = useState("1");
    const [responsedata, setResponseData] = useState("");

    //method to handle the form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", key);
        formData.append("type", type);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Loading...";
        submitButton.style.backgroundColor = "#6c757d";        

        //calling the extract method from AIService
        const response = await AIService.extract(formData);
        console.log(response);

        //Change button text back to "Submit" after response
        submitButton.disabled = false;
        submitButton.innerText = "Submit";
        submitButton.style.backgroundColor = "#0d6efd";

        //setting the data in the state
        setResponseData(response);
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <Card className="card">
                    <Card.Body>
                        <Card.Title> Challan </Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label> Upload Challan </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </Form.Group>                            
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>                        
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-8">
                <div className="data">
                    <h3> Extracted Data </h3>
                    
                    {/* Check if response data is not empty and reponsedata.status is success*/}
                    {responsedata ? (
                        <div class="invoice">
                            <div class="invoice-header">
                                <h1>Challan</h1>
                            </div>
                            <div class="invoice-details">                                
                                <p><strong>Date of Issue:</strong> {responsedata.date_of_issue}</p>
                                <p><strong>Time of Issue:</strong> {responsedata.time_of_issue}</p>
                                <p><strong>Vehicle Number:</strong> {responsedata.vehicle_number}</p>
                                <p><strong>Gross Weight:</strong> {responsedata.gross_weight}</p>
                                <p><strong>Tare Weight:</strong> {responsedata.tare_weight}</p>
                                <p><strong>Net Weight:</strong> {responsedata.net_weight}</p>
                            </div>                           
                        </div>
                    ) : (
                        <div>
                            <p> No data found </p>
                        </div>
                    )}
                    
                   </div>
            </div>
        </div>
    );
};

export default Challan;