//functional component to display the dashboard with two cards for invoice and challan. 
//By clicking on the card, the user will be redirected to the respective page.

import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const Dashboard = () => {
    return (
        <div className="dashboard">
            <CardGroup>
                <Card className="card">
                    <Link to="/invoice">
                        <Card.Body>
                            <Card.Title> Invoice </Card.Title>
                            <Card.Text> Extract Invoice </Card.Text>
                        </Card.Body>
                    </Link>
                </Card>
                <Card className="card">
                    <Link to="/challan">
                        <Card.Body>
                            <Card.Title> Challan </Card.Title>
                            <Card.Text> Extract Challan </Card.Text>
                        </Card.Body>
                    </Link>
                </Card>
            </CardGroup>
        </div>
    );
};

export default Dashboard;
