import logo from './logo.svg';
import './App.css';

//import routes and route from react-router-dom
import { Routes, Route } from "react-router-dom";

//import pages
import Dashboard from "./pages/dashboard";
import Invoice from "./pages/invoice";
import Challan from "./pages/challan";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/challan" element={<Challan />} />
      </Routes>
    </>
  );
}

export default App;
